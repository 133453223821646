import { Component, OnInit, PLATFORM_ID, Inject, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Location, formatDate, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Event } from '../models/event';
import { isPlatformServer } from '@angular/common';
import { TransferState, makeStateKey, Title, SafeResourceUrl, DomSanitizer, Meta } from '@angular/platform-browser';

import { environment } from '../../environments/environment';

import { EventService } from '../services/event.service';
import { AuthService } from '../services/auth.service';
import { RepetitionService } from '../services/repetition.service';
import { CategoryService } from '../services/category.service';
import { UtilsService } from '../services/utils.service';
import { SeoService } from '../services/seo.service';
import { ScrollService } from '../services/scroll.service';

import moment from 'moment';
import { EventRepetition } from '../models/event-repetition';
import { TeamService } from '../services/team.service';
import { QuestionWarningComponent } from '../question-warning/question-warning.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ImageObject } from '../models/image-object';
import { ReportEventComponent } from '../report-event/report-event.component';
import { Subscription } from 'rxjs';
import { Warning } from '../models/warning';


import { ShowEmbedComponent } from '../show-embed/show-embed.component';
import { FavoritesService } from '../services/favorites.service';
const ics = require('ics');

const CACHED_EVENT = makeStateKey<Event>('cachedEvent');

@Component({
  selector: 'app-event-single',
  templateUrl: './event-single.component.html',
  styleUrls: ['./event-single.component.scss']
})
export class EventSingleComponent implements OnInit, OnDestroy {

  isBrowser: boolean = false;
  event: Event;
  tags: string[];
  relatedEventsByOrganizers: Event[];
  relatedEventsByCategories: Event[];
  isLoading: boolean = true;
  seeAllRepetitions: boolean = false;
  environment: any;
  datesWithinWarning: boolean = false;
  warningContent: string;
  superProgram: Event[];
  superEvent: Event;
  userCanEdit: boolean = false;
  programPage = 0;
  programHits = 0;
  programMoreAvailable = false;
  productLD: any;
  metaProperties: string[] = environment.ui.showOnMetadata;
  lanSubs: Subscription;
  routeSubs: Subscription;
  videoID: string;
  videoSRC: SafeResourceUrl;
  categories: string[];
  expandedRep: boolean = false;
  isFavorite: boolean = false;

  //VMFEST
  isVMFest: boolean = false;

  constructor(
    private translate: TranslateService,
    private route: ActivatedRoute,
    public eventService: EventService,
    public sanitizer: DomSanitizer,
    public authService: AuthService,
    private teamService: TeamService,
    public repetitionService: RepetitionService,
    public categoryService: CategoryService,
    private favoriteService: FavoritesService,
    private titleService: Title,
    private scrollService: ScrollService,
    private router: Router,
    private state: TransferState,
    public snackBar: MatSnackBar,
    private meta: Meta,
    public dialog: MatDialog,
    public location: Location,
    @Inject(PLATFORM_ID) private platformId: any,
    private seo: SeoService,
    public utilsService: UtilsService) {
    this.environment = environment;
  }

  ngOnInit() {
    this.routeSubs = this.route.paramMap.subscribe(params => {
      //Reset loading to true, in case we stay on the same page (click on repetition)
      this.isLoading = true;
      const eventSlugFromRoute: string = params.get("slug");
      const cachedEvent: Event = this.state.get<Event>(CACHED_EVENT, null);

      //VM Fest
      this.isVMFest = this.route.snapshot.data?.custom === 'vm-fest';

      if (eventSlugFromRoute) {
        //Rendering on Server Side
        if (isPlatformServer(this.platformId)) {
          this.eventService.getEventBySlug(eventSlugFromRoute).pipe(first()).subscribe(
            (event: Event) => {
              if (event) {
                this.setupEventInfo(event);
                this.state.set<Event>(CACHED_EVENT, event);
              }
              this.isLoading = false;
            }
          );
        }
        //Rendering on Browser side
        if (isPlatformBrowser(this.platformId)) {
          this.isBrowser = true;
          //User is accessing directly the link of the event
          if (cachedEvent?.event_slug == eventSlugFromRoute) {
            this.isLoading = false;
            this.setupEventInfo(cachedEvent);
          } else {
            //User has navigated from the browser side to this event
            this.eventService.getEventBySlug(eventSlugFromRoute).pipe(first())?.subscribe(
              (event: Event) => {
                if (event) {
                  this.setupEventInfo(event);
                }
                this.isLoading = false;
              }
            );
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.state?.set<Event>(CACHED_EVENT, null);
    this.lanSubs?.unsubscribe();
    this.routeSubs?.unsubscribe();
  }

  setupEventInfo(event: Event) {
    //Maybe show a warning that affects the event
    this.setupWarning();
    //Reset the program
    this.superProgram = null;
    //Setup the event
    this.event = event;
    //Setup the event embedded videos
    this.setupEmbeddedVideo(event);
    //Setup the metatags and title for SEO purposes
    this.setupSEO(event);
    //Subscribe to the language change event
    this.lanSubs = this.translate.onLangChange.subscribe(() => {
      //If the language change while we are on this screen
      this.setupSEO(event);
    });
    //Setup the super event information
    if (event.type == 'super-event') {
      this.setupSuperProgram(event.id, this.programPage);
    }
    if (event.type == 'has-super' && event.super_event) {
      this.setupSuperEvent(event.super_event);
    }
    //Setup the tags associated with the event
    this.setupTags(event);
    //Setup the event categories
    this.setupCategories(event);
    //Setup the right "Edit" and "Remove" buttons depending on the user permissions
    this.setupUserPermissions(event);
    //Setup the event JSON+LD schema for structured data
    this.setupEventSchema(event);
    //Setup the related events by category and by organizer
    this.setupRelatedEvents(event);
    //Maybe add a no index meta tag
    this.maybeAddNoIndexMetaTag(event)
    //Favorite info
    this.isFavorite = this.favoriteService.isFavorite(this.event.event_slug);
  }

  maybeAddNoIndexMetaTag(event: Event) {
    if (this.hasPassed(event)) {
      this.meta.addTag({ name: 'robots', content: 'noindex' });
    }
  }

  setupWarning() {
    this.eventService.getWarning().pipe(first()).subscribe(
      (warning: Warning) => {
        if (warning?.showWarnings) {
          const fromDate = moment(new Date(warning.fromDate.seconds * 1000));
          const toDate = moment(new Date(warning.toDate.seconds * 1000));
          this.datesWithinWarning = ((this.event.repetitions?.length && this.event.repetitions) || [{startDate: this.event.startDate, endDate: this.event.endDate}]).findIndex((rep) => {
            const eventStart = moment(new Date(rep.startDate.seconds * 1000));
            const eventEnd = moment(new Date(rep.endDate.seconds * 1000));
            return eventStart.isBetween(fromDate, toDate) || eventEnd.isBetween(fromDate, toDate);
          }) != -1;
          if (this.datesWithinWarning) {
            this.warningContent = warning.warningContentNb;
            if (this.translate.currentLang == 'en') {
              this.warningContent = warning.warningContentEn;
            }
          }
        }
      }
    )
  }

  setupEmbeddedVideo(event: Event) {
    if (event.embeddedVideoURL) {
      if (event.embeddedVideoURL?.indexOf("youtu") != -1) {
        //It's a youtube video
        let videoURL = event.embeddedVideoURL.split("v=");
        //Short version of the youtube video URL
        if (event.embeddedVideoURL.indexOf("youtu.be/") != -1) {
          videoURL = event.embeddedVideoURL.split(".be/");
        }
        if (videoURL.length > 0) {
          this.videoID = videoURL[1];
        }
        if (this.videoID) {
          const ampersandPosition = this.videoID.indexOf('&');
          if (ampersandPosition != -1) {
            this.videoID = this.videoID.substring(0, ampersandPosition);
          }
          this.videoSRC = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.videoID + '?cc_lang_pref=nb&cc_load_policy=1');
        }
      }
      if (event.embeddedVideoURL.indexOf("vimeo") != -1) {
        //It's a youtube video
        const videoURL = event.embeddedVideoURL.split("vimeo.com/");
        if (videoURL.length > 0) {
          this.videoID = videoURL[1];
        }
        if (this.videoID) {
          const ampersandPosition = this.videoID.indexOf('&');
          if (ampersandPosition != -1) {
            this.videoID = this.videoID.substring(0, ampersandPosition);
          }
          this.videoSRC = this.sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.videoID);
        }
      }
      if (event.embeddedVideoURL.indexOf("panopto") != -1) {
        //It's a youtube video
        const videoURL = event.embeddedVideoURL.split("id=");
        if (videoURL.length > 0) {
          this.videoID = videoURL[1];
        }
        if (this.videoID) {
          const ampersandPosition = this.videoID.indexOf('&');
          if (ampersandPosition != -1) {
            this.videoID = this.videoID.substring(0, ampersandPosition);
          }
          this.videoSRC = this.sanitizer.bypassSecurityTrustResourceUrl('https://ntnu.cloud.panopto.eu/Panopto/Pages/Embed.aspx?id=' + this.videoID + "&autoplay=false&offerviewer=true&showtitle=true&showbrand=false&start=0&interactivity=all");
        }
      }
    } else {
      this.videoSRC = null;
    }
  }

  setupTags(event) {
    if (event.tags?.length > 0) {
      this.tags = event.tags.split(",");
    }
  }

  setupCategories(event) {
    this.categories = this.event.categories.map((categoryID) => this.categoryService.getCategoryName(categoryID));
  }

  setupSEO(event: Event) {
    //Set the title of the page
    this.titleService.setTitle(this.utilsService.getTitle(event) + " " + this.displayDate(event.startDate) + " " + this.displayTime(event.startDate) + ' | ' + environment.content.siteName);
    this.seo.generateTags({
      title: this.utilsService.getTitle(event) + ' | ' + environment.content.siteName,
      description: this.utilsService.getPlainTextFromHtml(event["desc_" + (this.translate.currentLang || 'nb')]),
      image: this.getEventImage(event),
      imageWidth: 900,
      imageHeight: 600,
      imageAlt: event.images[0]?.alt || '',
      slug: 'event/' + event.event_slug
    })
  }

  getEventImage(event: Event) {
    const firstImage: ImageObject = event.images[0];
    return firstImage?.urlLarge || environment.siteURL + '/assets/pictures/placeholder-facebook.jpg';
  }

  setupSuperEvent(superEventID: string): void {
    const unsub = this.eventService.getEventFromFirestore(superEventID,
      (superEvent) => {
        this.superEvent = superEvent;
        unsub();
      }
    );
  }

  setupSuperProgram(superID: string, programPage: number) {
    let sub = this.eventService.getChildrenEvents(superID, programPage).pipe(
      map((results: any) => {
        if (results?.data?.events) {
          this.programHits = results.data.events.totalCount;
          this.programMoreAvailable = results.data.events.hasMore;
        }
        return results;
      }),
      this.eventService.getTransformGraphQLEventsWithPagination()
    ).subscribe(
      (childrenEvents) => {
        if (this.superProgram) {
          this.superProgram = this.superProgram.concat(childrenEvents).sort((a, b) => { return a.startDate < b.startDate ? -1 : 1; });
        } else {
          this.superProgram = childrenEvents;
        }
        sub.unsubscribe();
      }
    )
  }

  loadMoreProgramEvents() {
    this.programPage = this.programPage + 1;
    this.setupSuperProgram(this.event.id, this.programPage);
  }

  setupEventSchema(event: Event) {
    this.productLD = this.utilsService.getEventSchema(event, this.superEvent, this.superProgram);
  }

  setupUserPermissions(event: Event) {
    if (isPlatformBrowser(this.platformId)) {
      let userAuthSubs = this.teamService.canUserEditEvent(event).subscribe(
        (canEdit) => {
          userAuthSubs.unsubscribe();
          this.userCanEdit = canEdit;
        }
      );
    }
  }

  setupRelatedEvents(event: Event) {
    this.eventService.getRelatedEventsByOrganizers(event, this.isVMFest).pipe(first()).subscribe(
      (events: Event[]) => {
        this.relatedEventsByOrganizers = events?.filter(ev => ev != null).slice(0, 3) || [];
      }
    );
    this.eventService.getRelatedEventsByCategories(event, this.isVMFest).pipe(first()).subscribe(
      (events: Event[]) => {
        const orgTitles = this.relatedEventsByOrganizers?.map(evOrg => evOrg.title_nb) || [];
        this.relatedEventsByCategories = events?.filter(ev => ev && orgTitles.indexOf(ev.title_nb) === -1).slice(0, 3) || [];
      }
    );
  }

  hasPassed(event: Event) {
    let filteredRepetitions: any = [];
    if (event.repetitions?.length > 0) {
      filteredRepetitions = event.repetitions.filter(rep => moment().isBefore(moment((rep.endDate?.seconds || rep.startDate?.seconds) * 1000))) || [];
    }
    return filteredRepetitions.length == 0 && moment().isAfter(moment(event.endDate.seconds * 1000));
  }

  goFrontPage() {
    let prevURL = this.scrollService.getPreviousUrl();
    if (prevURL.indexOf("/filter") != -1 || prevURL == '/') {
      this.location.back();
    } else {
      this.router.navigate(['/']);
    }
  }

  day(timestamp: any) {
    let date = moment(timestamp).isValid() ? moment(timestamp) : moment(timestamp.seconds * 1000);
    return date.format("D MMMM YYYY");
  }

  displayDate(timestamp: any) {
    let date = moment(timestamp).isValid() ? moment(timestamp) : moment(timestamp.seconds * 1000);
    return date.format(this.utilsService.getDateFormatForSingle());
  }

  displayDateRepetition(timestamp: any) {
    let date = moment(timestamp).isValid() ? moment(timestamp) : moment(timestamp.seconds * 1000);
    return date.format(this.utilsService.getDateFormatForRepetition());
  }

  displayTime(timestamp: any) {
    let time = moment(timestamp).isValid() ? moment(timestamp) : moment(timestamp.seconds * 1000);
    return time.format(this.utilsService.getTimeFormatForSingle());
  }

  getStartDate(rep: EventRepetition) {
    const startDate = moment(rep.startDate).isValid() ? moment(rep.startDate) : moment(rep.startDate.seconds * 1000);
    return startDate.format(this.utilsService.getDateFormat()) + ((rep.startTime && " @ " + this.utilsService.getLocalizedHour(rep.startTime)) || '');
  }

  getEndTime(rep: EventRepetition) {
    if (rep.duration && rep.duration > 0) {
      const startDate = moment(rep.startDate).isValid() ? moment(rep.startDate) : moment(rep.startDate.seconds * 1000);
      return " - " + startDate.add(rep.duration, 'minutes').format(this.utilsService.getTimeFormat());
    }
    return '';
  }

  getOrganizerNames(organizers: any): string {
    return (organizers && organizers.length > 0 && organizers.map(org => org.name).join(", ")) || '';
  }

  getCategoryNames(categories: string[]): string {
    return categories?.map((catID: string) => this.categoryService.getCategoryName(catID)).join(", ") || '';
  }

  getRelatedStartDate(event: Event) {
    const momentStartDate = moment(event.startDate).isValid() ? moment(event.startDate) : moment(event.startDate.seconds * 1000);
    const timePart = event.type != 'super-event' ? ', ' + this.translate.instant("at") + ' ' + this.utilsService.getTimeFormatForSingle() : '';
    return momentStartDate.format(this.utilsService.getDateFormat(true) + timePart);
  }

  deleteEvent(eventId: string): void {
    let dialogRef = this.dialog.open(
      QuestionWarningComponent, {
      width: '400px',
      data: {
        title: this.translate.instant("Delete event"),
        question: this.translate.instant("Are you sure that you want to remove this event and all its repetitions?")
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.eventService.deleteEvent(eventId)
          .then(
            () => {
              this.router.navigate(['/']);
              this.snackBar.open(this.translate.instant("Your event was successfully deleted."), null, {
                duration: 4000,
              });
            }
          )
          .catch(
            () => this.snackBar.open(this.translate.instant("There was a problem deleting your event. Please contact us."), null, {
              duration: 4000,
            })
          );
      }
    });
  }


  reportEvent(eventId: string): void {
    let dialogRef = this.dialog.open(
      ReportEventComponent, {
      width: '400px',
      data: {
        eventId
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      if (response && response.success) {
        this.snackBar.open(this.translate.instant("This event was successfully reported."), null, {
          duration: 4000,
        });
      }
      if (response && !response.success) {
        this.snackBar.open(this.translate.instant("There was a problem reporting this event. Please contact us."), null, {
          duration: 4000,
        });
      }
    });
  }

  //TRDEvents style
  getDate(date): string {
    const gDate = date?.seconds ? new Date(date.seconds * 1000) : date;
    if (this.translate.currentLang == 'nb') {
      if (this.event.type == 'super-event') {
        return formatDate(gDate, "d. MMMM yyyy", 'nb-NO');
      } else {
        return formatDate(gDate, "d. MMMM yyyy, HH:mm", 'nb-NO');
      }
    } else {
      if (this.event.type == 'super-event') {
        return formatDate(gDate, "d MMMM yyyy", 'nb-NO');
      } else {
        return formatDate(gDate, "d MMMM yyyy, HH:mm", 'nb-NO');
      }
    }
  }


  //TODO DELETE
  
  openEmbed(): void {
    this.dialog.open(
      ShowEmbedComponent, {
      width: '500px',
      data: {
        eventID: this.event.id
      }
    });
  }

  //Returns a link to prefill the events fill on Google Calendar
  getLinkForGoogleCalendar() {
    const location: string = this.event.mode !== 'online' && this.event.venue?.address ? '&location=' + this.event.venue?.address : '';
    const dates: string = moment(this.event.startDate.seconds, "X").format("YYYYMMDD[T]HHmmss") + "/" + moment(this.event.endDate.seconds, "X").format("YYYYMMDD[T]HHmmss");
    return `http://www.google.com/calendar/event?action=TEMPLATE&dates=${dates}&text=${this.event.title_nb}&details=${this.event.desc_nb}${location}`;
  }

  //Create an ICS file and starts a downloading
  generateICS() {
    const startArray: any = moment(this.event.startDate.seconds, "X").format('YYYY-M-D-H-m').split("-");
    const latLon: any = this.event.mode !== 'online' && this.event.venue?.location ? { lat: this.event.venue.location.latitude, lon: this.event.venue.location.longitude } : null;
    const organizer: any = this.event.organizers[0].email ? { name: this.event.organizers[0].name, email: this.event.organizers[0].email } : null;
    const icsEvent: any = {
      start: startArray,
      duration: { hours: Math.floor(this.event.duration / 60), minutes: this.event.duration % 60 },
      title: this.event.title_nb,
      description: this.event.desc_nb,
      location: this.event.venue?.name || '',
      url: environment.siteURL + '/event/' + this.event.event_slug,
      categories: this.event.categories,
    };
    if (latLon) {
      icsEvent.geo = latLon;
    }
    if (organizer) {
      icsEvent.organizer = organizer;
    }
    ics.createEvent(icsEvent, (error, value) => {
      if (error) {
        console.error(error)
      } else {
        this.downloadFile(value, icsEvent.title);
      }
    })
  }

  //Prompt to download a file with the given data and filename
  downloadFile(data: any, filename: string) {
    var a = document.createElement("a");
    document.body.appendChild(a);
    const blob = new Blob([data], { type: 'text/calendar' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename + '.ics';
    a.click();
    window.URL.revokeObjectURL(url);
  }


  toggleFavorite(eventSlug: string) {
    this.favoriteService.toggle(eventSlug);
    this.isFavorite = this.favoriteService.isFavorite(eventSlug);
  }

}
