import { EventRepetition } from './event-repetition';
import { Venue } from './venue';
import { Price } from './price';
import { ImageObject } from './image-object';
import { Log } from './log';

export class BookingsStats {
  activeBookings?: number;
  availableTickets?: number;
  adminBookings?: number;
  attendedTickets?: number;
  bookingsCount?: number;
  bookedTickets?: number;
  noShowTickets?: number;
  partiallyAttended?: number;
  totalSales?: number;
  waitingListTickets?: number; 
}

export class Event {
  activityTypes?: string;
  activeTickets?: number;
  availableTickets?: number;
  ageRestriction: string;
  author_id: string;
  bookingsStats?: BookingsStats;
  cancellationPeriod?: number;
  cancellationWarningSent?: boolean;
  categories: string[];
  created_at?: any;
  desc_en?: string;
  desc_nb?: string;
  duration?: number;
  editableBy?: string;
  embeddedVideoURL?: string;
  endDate?: any;
  endTime?: string;
  eatTogether?: boolean;
  eventCancelled?: boolean;
  eventLink?: string;
  eventSoldOut?: boolean;
  event_slug: string;
  facebookURL?: string;
  fieldEvent?: boolean;
  id?: string;
  images: ImageObject[];
  imageBase64?: string;
  imageCaption?: string;
  importedFrom?: string;
  isFeatured?: string;
  logs?: Log[];
  maximumAge?: number;
  mazeMapPoi?: string;
  minimumAge?: number;
  moreInfoURL?: string;
  mode?: string;
  noTicketsInfo?: boolean;
  organizers: any[];
  //door or manual_invoice
  paymentMethod?: string;
  prices?: Price[];
  priceOption?: string;
  publishingDate: any;
  publishingOption: string;
  publishingTime: string;
  regularPrice?: number;
  reducedPrice?: number;
  registrationEnabled: boolean;
  repetitions: EventRepetition[];
  sourceEventID?: string;
  startTime?: string;
  startDate: any;
  status: string;
  streamingURL?: string;
  summary_en?: string;
  summary_nb?: string;
  super_event?: string;
  super_terms?: string;
  synchro?: any;
  synchroDestinations?: string[];
  tags?: string;
  //noTicketsInfo, free, ticketsInfo
  ticketsInformation: string;
  ticketsURL?: string;
  ticketsFromDate?: any;
  ticketsFromTime?: string;
  title_nb: string;
  title_en?: string;
  type?: string;
  updated_at?: any;
  validCacheStatus?: boolean;
  venue?: any;
  venueNote?: string;
  venueObj?: Venue;
  videosURL?: string;
}
