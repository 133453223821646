<div class="mobile-hint">
    {{'Tap on a booking to see details and actions' | translate}}
</div>

<div class="bookings-header">
    <h2>{{ showPastBookings ? ('Past bookings' | translate) : ('Upcoming bookings' | translate) }}</h2>
    <button mat-button (click)="togglePastBookings()">
        {{ (showPastBookings ? 'Show upcoming bookings' : 'Show past bookings') | translate }}
    </button>
</div>

<table class="booking-list">
    <thead>
        <tr>
            <th>{{'Booking number' | translate}}</th>
            <th class="event-cell">{{'Event' | translate}}</th>
            <th class="sortable">
                {{'Date' | translate}}
                <span class="caret" [class.ascending]="!showPastBookings">
                    ▼
                </span>
            </th>
            <th>{{'Status' | translate}}</th>
            <th>{{'Total' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let b of filteredBookings">
            <tr class="expandable-row" 
                [class.selected]="selectedBooking?.id === b.id"
                (click)="selectBooking(b)">
                <td>
                    <span class="expand-icon" [class.expanded]="selectedBooking?.id === b.id">▶</span>
                    {{b.bookingNumber}}
                </td>
                <td class="event-cell">
                    <p class="event-title">{{b.eventTitle}}</p>
                </td>
                <td>{{b.startDate?.seconds ? getDateTime(b.startDate.seconds) : 'N/A'}}</td>
                <td>{{getStatus(b.status)}}</td>
                <td>{{b.totalAmount | currency:'NOK':'symbol':'0.2-2':'nb'}}</td>
                <div class="expand-hint">
                    {{'Click to see details and actions' | translate}}
                </div>
            </tr>
            <tr *ngIf="selectedBooking?.id === b.id">
                <td colspan="5" class="details-cell">
                    <div class="guest-editor" fxLayout="column" fxLayoutGap="30px">
                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Status' | translate}}</span>
                                <span class="value">{{getStatus(selectedBooking.status)}}</span>
                            </div>
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Booking number' | translate}}</span>
                                <span class="value">{{selectedBooking.bookingNumber}}</span>
                            </div>
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Name' | translate}}</span>
                                <span class="value">{{selectedBooking.customerName}}</span>
                            </div>
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Phone' | translate}}</span>
                                <span class="value">{{selectedBooking.customerPhone}}</span>
                            </div>
                            <div class="info-block" fxLayout="column" *ngIf="selectedBooking.customerAddress?.formattedAddress">
                                <span class="label">{{'Address' | translate}}</span>
                                <span class="value">{{trim(selectedBooking.customerAddress.formattedAddress)}}</span>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                            <div class="info-block" fxFlex="100" fxFlex.lt-lg="auto" fxLayout="column">
                                <span class="label">{{'Comments' | translate}}</span>
                                <span class="value">{{selectedBooking.comments || ('None' | translate)}}</span>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Event' | translate}}</span>
                                <span class="value">{{trim(selectedBooking.eventTitle)}}</span>
                            </div>
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Venue' | translate}}</span>
                                <span class="value">{{trim(selectedBooking.venueName)}}</span>
                            </div>
                            <div class="info-block" fxFlex="130px" fxFlex.lt-lg="auto" fxLayout="column">
                                <span class="label">{{'Start date' | translate}}</span>
                                <span class="value">{{selectedBooking.startDate?.seconds ? getDateTime(selectedBooking.startDate?.seconds) : 'N/A'}}</span>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Payment method' | translate}}</span>
                                <span class="value">{{ selectedBooking.paymentMethod == 'invoice' ? ('Invoice' | translate) : ('At the door' | translate)}}</span>
                            </div>
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Cancellation deadline' | translate}}</span>
                                <span class="value" *ngIf="maxCancellationDate">{{ maxCancellationDate }}</span>
                            </div>
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Tickets' | translate}}</span>
                                <span class="value"><span *ngFor="let ticket of selectedBooking.tickets">{{ ticket.numberTickets > 0 ?
                                        (ticket.numberTickets + 'x ' + getTicketType(ticket)) : ''}}</span></span>
                            </div>
                            <div class="info-block" fxLayout="column">
                                <span class="label">{{'Total' | translate}}</span>
                                <span class="value">{{ selectedBooking.totalAmount | currency:'NOK':'symbol':'0.2-2':'nb' }}</span>
                            </div>
                        </div>

                        <div *ngIf="!showPastBookings">
                            <div class="full-width" fxLayoutGap="15px" fxLayout="column">
                                <app-booking-edition [isAdmin]="false" [booking]="selectedBooking"></app-booking-edition>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>