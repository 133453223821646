import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { QuestionWarningComponent, QuestionData } from '../question-warning/question-warning.component';
import { TicketTypeService } from '../services/ticket-type.service';

@Component({
  selector: 'app-add-ticket-type',
  templateUrl: './add-ticket-type.component.html',
  styleUrls: ['./add-ticket-type.component.scss']
})
export class AddTicketTypeComponent implements OnInit {
  
  formIsWorking: boolean = false;
  addTicketTypeForm = new FormGroup({
    id: new FormControl(),
    name_nb: new FormControl('', [Validators.required, Validators.maxLength(80)]),
    name_en: new FormControl('', [Validators.required, Validators.maxLength(80)]),
  });

  constructor(
    private translate: TranslateService,
    public ticketTypeService: TicketTypeService,
    public dialogRef: MatDialogRef<AddTicketTypeComponent>,
    public snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  //Prevent closing the dialog when the user press Enter focused on the "name" field
  preventClosing(e) {
    e.preventDefault();
  }

  onCancel() {
    this.dialogRef.close();
  }

  addTicketType() {
    this.formIsWorking = true;
    if (this.addTicketTypeForm.valid) {
      const tt = { ...this.addTicketTypeForm.value, id: this.addTicketTypeForm.value.name_en.toUpperCase().replace(/ /g, "_") };
      this.ticketTypeService.addTicketType(tt)
        .then(() => {
          this.formIsWorking = false;
          this.dialogRef.close(tt);
          this.snackBar.open(this.translate.instant("Ticket type created successfully"), null, {
            duration: 4000,
          });
        })
        .catch(error => {
          this.formIsWorking = false;
          console.error(error);
          this.snackBar.open(this.translate.instant("There was an error while creating your ticket type"), null, {
            duration: 4000,
          });
        });
    }
  }

}
